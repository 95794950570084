<template>
    <iframe
        ref="knowbot"
        tabindex="0"
        :src="src"
        title="Knowbot"
        width="100%"
        height="100%"
        class="border-0"
        :allow="`microphone *;`"
        allowfullscreen
    />
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'

    import { ALLOWED_ORIGINS, CHAT_POST_MESSAGE_EVENTS } from '../utils'


    export default defineComponent({
        props: {
            config: {
                type: Object as PropType<KnowbotChatConfig>,
                required: true,
            },
        },

        data() {
            return {
                targetOrigin: 'https://chat.applai.io',
            }
        },

        computed: {
            knowbot(): KnowbotModel | null {
                return this.$store.get('Knowbot/knowbot')
            },

            iframe(): HTMLIFrameElement | null {
                return this.$refs.knowbot as HTMLIFrameElement
            },

            src(): string {
                if (!this.knowbot)
                    return ''

                return `${this.targetOrigin}/chatbot/${this.knowbot.externalId}?waitForInit=true`
            },
        },

        created() {
            window.addEventListener('message', this.message)
        },

        beforeDestroy() {
            window.removeEventListener('message', this.message)
        },

        methods: {
            message(event: MessageEvent): void {
                if (!ALLOWED_ORIGINS.has(event.origin))
                    return

                if (!CHAT_POST_MESSAGE_EVENTS.has(event.data.type))
                    return

                if (event.data.type === 'init')
                    this.iframe?.contentWindow?.postMessage(this.config, this.targetOrigin)
                else if (event.data.type === 'source-clicked')
                    window.open(event.data.url, '_blank')
                else if (event.data.type === 'close')
                    this.$emit('close')
            },
        },
    })
</script>

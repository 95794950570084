import { Route } from 'vue-router'
import { dialog } from '@plugins/dialog'
import JourneyModal from '@modules/Journeys/components/JourneyModal.vue'


/**
 * Extract all LearningModel from a JourneyModel
 */
export const getAllContent = (journey: JourneyModel): LearningModel<'container' | 'module' | 'development-plan'>[] => {
    return journey.levels.reduce((acc, item) => {
        return acc.concat(item.contents)
    }, [] as LearningModel<'container' | 'module' | 'development-plan'>[])
}

export async function showJourneyModal(query: Route['query']): Promise<void> {
    const journeyId = query['journey']

    if (journeyId)
        await dialog.modal(JourneyModal, { value: Number(journeyId) })
}

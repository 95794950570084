<template>
    <div class="d-flex flex-wrap align-items-start justify-content-between g-3 mb-6">
        <div :class="{ 'skeleton-container': busy }">
            <slot>
                <h3 :class="{ 'mb-0': !hasDescription }">
                    <span
                        :class="{ 'skeleton-text' : busy }"
                        v-text="!busy ? props.title : ''"
                    />
                </h3>
                <p
                    v-if="hasDescription"
                    class="text-muted mb-0"
                >
                    <span
                        :class="{ 'skeleton-text' : busy }"
                    >
                        <slot
                            v-if="!busy"
                            name="description"
                        >{{ props.description }}</slot>
                    </span>
                </p>
            </slot>
        </div>
        <div class="d-flex flex-wrap g-2">
            <slot name="right" />
        </div>
    </div>
</template>


<script lang="ts" setup>
    import { computed, useSlots } from 'vue'

    type Props = {
        title?: string;
        description?: string;
        busy?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        title: undefined,
        description: undefined,
    })

    const slots = useSlots()

    const hasDescription = computed(() => !!props.description || !!slots.description)

</script>

<style lang="scss" scoped>
    h3 {
        .skeleton-text {
            width: 12rem;
        }
    }
    p {
        max-width: 50rem;

        .skeleton-text {
            width: 20rem;
        }
    }
</style>
export const ALLOWED_ORIGINS = new Set([
    'https://chat.applai.dk',
    'https://chat.applai.io',
])

export const CHAT_POST_MESSAGE_EVENTS = new Set([
    'source-clicked',
    'close',
    'init',
])

export const SCRAPER_POST_MESSAGE_EVENTS = new Set([
    'open_group_id_modal',
    'crawl',
])
